import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "changelog"
    }}>{`Changelog`}</h1>
    <h2 {...{
      "id": "050"
    }}>{`0.5.0`}</h2>
    <ul>
      <li parentName="ul">{`Perfomance improvements`}</li>
      <li parentName="ul">{`Bonus pole effects are less powerful`}</li>
      <li parentName="ul">{`Bots and gold bots are upgraded more slowly`}</li>
      <li parentName="ul">{`Players can only deal 1 damage to guardians at each hit`}</li>
      <li parentName="ul">{`Laser can hurt guardians`}</li>
      <li parentName="ul">{`Items update: `}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Item`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`0.4.x`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`0.5.0`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Laser pistol`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Gain: 0.7`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Gain: 0.6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Missile launcher`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Gain: 0.7`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Gain: 0.6`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Electro-Blast`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Gain: 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Gain: 0.8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Nonlinear Shotgun`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Gain: 1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Gain: 0.8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Ultra Electro-Blast`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Power: 7, Ammo Gain: 1.5`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Power: 6, Ammo Gain: 1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Cyber Comet`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Ammo Gain: 1.5`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Ammo Gain: 1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Medical Chipset`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 40, Armor +1, Armor Regen +60`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 45, Armor +2, Armor Regen +60`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Energy Cell`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 40, Armor +1, Stamina Max +0.3`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 45, Armor +2, Stamina Max +0.3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Copper Plate`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 40, Armor +1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 45, Armor +2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Mania Cable`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 80, Armor +3, Stamina Max +0.3`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, Armor +4, Stamina Max +0.3`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Safety Funnel`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +1, Shield +2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +3, Shield +2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Exoskeleton Component`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +3, Ammo Power +1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +4, Ammo Power +1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Electronic Bullet`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Arrow +1, Nucleus +1, Life Steal +0.7`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Arrow +1, Nucleus +1, Life Steal +0.8`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Iron Cube`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +4, Shield +1, Items on the next line cost 50 Coppers more.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +6, Shield +1, Items on the next line cost 50 Coppers more.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Nuclear Ammo`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 280, Ammo Power +5`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 330, Ammo Power +5`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Nucleo Component`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +4, You gain 2 additional Armor for each Nucleus you have.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +5, You gain 2 additional Armor for each Nucleus you have.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Medical Big box`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +6, Armor Regen +180, Life Steal +1, Shield +1`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +8, Armor Regen +180, Life Steal +1, Shield +1`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Plasma Loading System`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 200, Ammo Gain +1, Ammo Power +2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 250, Ammo Gain +1, Ammo Power +2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Secured Battle Helmet`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Armor +3, Shield +1, Armor Regen +50, Arrow +2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Armor +5, Shield +1, Armor Regen +50, Arrow +2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Metallic Armor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 85, 7 Armors`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, 8 Armors`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Bulletproof Vest`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 85, 4 Armors, Stamina Max +1.2`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, 5 Armors, Stamina Max +1.2`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Fashion Armor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 85, 4 Armors, You gain 15 additional Coppers when you kill a GoldBot.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, 5 Armors, You gain 15 additional Coppers when you kill a GoldBot.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Polymer Plating`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 85, 4 Armors, Ammo Gain +0.35.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, 4 Armors, Ammo Gain +0.35`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Hacker Armor`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`Cost: 85, 5 Armors, You gain 3 additional Coppers each 2 seconds when you are loading the Bonus Pole.`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`Cost: 90, 6 Armors, You gain 3 additional Coppers each 2 seconds when you are loading the Bonus Pole.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      